<template>
  <div class="card card-body filterItem">
    <div class="row">
      <div class="right-dash col-12 col-md-9">
        <div class="row no-gutters flex-md-row">
          <div class="col-md-3 col-5 sportthumble d-lg-block">
            <div class="select-fav">
              <input type="checkbox" class="custom-control-input" :id="`fab-${itemIndex}`"/>
              <label class="fav" :for="`fab-${itemIndex}`"></label>
            </div>
            <b-img-lazy v-bind="mainProps" :src="imgMainUrl" alt="main-image" width="100" height="100"></b-img-lazy>
          </div>
          <div
            class="col-md-9 col-7 pl-md-4 d-flex text-left flex-column position-static"
          >
            <h4 class="title">
              {{ hotel.name }}
              <!-- <b-badge
                :variant="hotel.status === 'OK' ? 'success' : 'danger'"
                >{{ hotel.status }}</b-badge
              > -->
            </h4>
            <div class="d-flex">
              <b-form-rating class="ui rating pl-0" variant="warning" inline :value="grade" readonly></b-form-rating>
            </div>

            <h5 class="d-block mt-3">
              <strong> Trip Duration : </strong>{{ tripDuration }}
              <i class="far fa-calendar-alt"></i>
            </h5>

            <h5>
              <strong> Borad Basis : </strong>
              {{ this.boardBasis }}
            </h5>

            <h5>
              <strong> Room Class : </strong>
              double or tween standard
            </h5>

            <span class="d-block">
              {{ this.roomName }}
            </span>

            <button class="btn btn-primary btn-selectRoom" @click="orderNow">
              RESERVE
            </button>
          </div>
        </div>
      </div>
      <div class="actionRoom col-12 col-md-3">
        <!-- <h4 class="mb-0">Very good</h4> -->
        <!-- <p>reviews {{ reivews }}</p> -->
        <p>{{ explainHotel }}</p>
        <p>
          <span class="large">{{ symbolCurrency }} {{ price }}</span>
          {{ codeCurrency }}
        </p>
        <button class="btn btn-primary btn-selectRoom" @click="orderNow">
          RESERVE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormRating, BImgLazy } from 'bootstrap-vue';
import dayjs from 'dayjs';
import getSymbolFromCurrency from 'currency-symbol-map';

export default {
  name: 'HotelItem',
  components: {
    BFormRating,
    BImgLazy,
  },
  props: {
    hotel: {
      type: Object,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'img-fluid rounded',
      },
      room: null,
      imgMainUrl: '',
      explainHotel: '',
      grade: 0,
      tripDuration: '',
      boardBasis: '',
      roomName: '',
      reivews: 55,
      night: 0,
      adults: 0,
      price: 0,
    };
  },
  computed: {
    ...mapGetters({
      hotelDetails: 'GET_HOTEL_DETAIL_DATA',
    }),
    symbolCurrency() {
      return getSymbolFromCurrency(this.hotel.currency);
    },
    codeCurrency() {
      return `( ${this.hotel.currency} )`;
    },
  },
  created() {
    this.setHotelValues();
  },
  watch: {
    hotel() {
      this.setHotelValues();
    },
  },
  methods: {
    setHotelValues() {
      this.grade = Number(this.hotel.grade);

      const checkIn = dayjs(this.hotel.checkIn),
        checkOut = dayjs(this.hotel.checkOut);
      this.tripDuration = `${checkIn.format('dddd DD/MM')} - ${checkOut.format(
        'dddd DD/MM',
      )}`;
      this.night = checkOut.diff(checkIn, 'day');

      this.hotel.rooms.sort((a, b) => a.rates[0].net > b.rates[0].net);
      this.room = this.hotel.rooms[0];
      this.boardBasis = this.room.boardName;
      this.vboardBasis = this.hotel.boardCode;
      this.roomName = this.room.name;
      this.adult = this.room.rates[0].adults;
      this.child = this.room.rates[0].children;
      this.price = this.hotel.Price;
      this.explainHotel = `Night ${this.night} , Adult ${this.adult}`;
      if (this.child > 0) {
        this.explainHotel += `, Children ${this.child}`;
      }
      this.imgMainUrl = this.hotel.hotelImage;
    },
    fetchData() {},
    orderNow() {
      const { query } = this.$route;
      const body = {
        contry: query.country,
        dest: query.dest,
        code: this.hotel.code,
        roomType: this.room.code,
        dateFrom: this.hotel.checkIn,
        dateTo: this.hotel.checkOut,
        adult: this.adult,
        child: this.child,
        ages: query.ages,
        categoryId: query.categoryId,
        currency: this.hotel.currency,
      };
      this.$router.push({ path: '/product-hotelbeds', query: body });
    },
  },
};
</script>

<style scoped>
.card-body {
  direction: ltr;
}
.right-dash {
  border-right: 1px dashed;
}
.dVacation-pack .ticket-topPart-big .box,
.dVacation-pack .ticket-bottomPart-big .box {
  min-width: 83px;
}
@media (max-width: 479px) {
  .dVacation-pack .ticket-topPart-big .box,
  .dVacation-pack .ticket-bottomPart-big .box {
    min-width: 62px;
  }
  .card-body h5 {
    font-weight: 400;
    font-size: 13px;
    margin-top: 3px !important;
    margin-bottom: 5px !important;
  }
  .card-body button {
    margin: 5px 0px;
  }
}
.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}
.ui.rating {
  padding-right: 0;
}
.itemTags {
  line-height: 30px;
}
.table td {
  text-align: center;
}
.flight-select {
  position: absolute;
  top: calc(50% - 14px);
  right: 0;
  padding: 0;
}
.filterItem {
  position: relative;
  padding: 10px;
  box-shadow: none;
}
</style>
<style>
.flight-select .custom-control {
  padding-left: 0;
}
.flight-select .custom-control-label {
  padding-right: 1.2rem;
}
.flight-select .custom-control-label::before,
.flight-select .custom-control-label::after {
  right: 0;
}
</style>
